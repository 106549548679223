import {QuoteProps} from 'components/QuoteCarousel/CustomerQuote'
import {graphql, useStaticQuery} from 'gatsby'
import {UsePartnerQuotesQuery} from 'landing-gatsby-graphql-generated'
import invariant from 'tiny-invariant'

const usePartnerQuotes = () => {
  const data = useStaticQuery<UsePartnerQuotesQuery>(graphql`
    query UsePartnerQuotes {
      trelloLogo: file(relativePath: {eq: "customers/trello.webp"}) {
        ...AvatarImage
      }
    }
  `)

  const trelloLogo = data.trelloLogo?.childImageSharp?.fixed
  invariant(trelloLogo, 'expected to find trello logo')

  const quotes: QuoteProps[] = [
    {
      text:
        'Getting started with Hello Epics is super simple, and it’s completely customizable to your workflow.',
      credit: {
        name: '',
        company: {
          name: 'Trello',
          url: 'https://trello.com',
          logoSrc: trelloLogo,
        },
      },
    },
  ]

  return quotes
}

export default usePartnerQuotes
