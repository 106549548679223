import React from 'react'
import {breakpoint, margin, typography} from 'styles'

import image1 from 'images/popular-uses-icons/1.svg'
import image2 from 'images/popular-uses-icons/2.svg'
import image3 from 'images/popular-uses-icons/3.svg'
import unicodeNonbreakingSpace from 'styles/constants/unicodeNonbreakingSpace'
import Card, {CardProps} from 'components/ThreeColumnGrid/Card'
import ThreeColumnGrid from 'components/ThreeColumnGrid'
import styled from '@emotion/styled'
import Wrapper from 'components/Wrapper'

const cards: CardProps[] = [
  {
    image: image1,
    body: `Integrates with your boards in${unicodeNonbreakingSpace}minutes.`,
  },
  {
    image: image2,
    body: 'Looks and feels just like Trello.',
  },
  {
    image: image3,
    body: `Complements any existing Trello${unicodeNonbreakingSpace}setup.`,
  },
]

const Headline = styled.h3({
  ...typography.headline3,
  textAlign: 'center',

  [breakpoint.mediumAndUp]: {
    ...typography.headline2,
    ...margin.bottom(24),
  },
})

const GettingStarted: React.FunctionComponent = () => (
  <Wrapper>
    <Headline>Simple and easy to use, yet mighty&nbsp;powerful</Headline>
    <ThreeColumnGrid css={{...margin.bottom(24)}}>
      {cards.map((card, index) => (
        <Card {...card} key={index} imageGap={16} />
      ))}
    </ThreeColumnGrid>
  </Wrapper>
)

export default GettingStarted
