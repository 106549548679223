import React from 'react'

import SiteMetadata from 'components/SiteMetadata'
import WhatPartnersAreSaying from 'components/CampaignPage/blades/WhatPartnersAreSaying'
import GettingStarted from 'components/CampaignPage/blades/GettingStarted'
import AboutUs from 'components/CampaignPage/blades/AboutUs'
import HowToGetStarted from 'components/home/blades/HowToGetStarted'
import BottomCTA from 'components/BottomCTA'
import DemoBlade from 'components/DemoBlade'
import PageWrapper from 'components/PageWrapper'

const CampaignPage = () => (
  <PageWrapper smallSpacing={64} mediumAndUpSpacing={100 as any}>
    <SiteMetadata />
    <DemoBlade
      title="The missing organizational layer of Trello."
      subtitle="Hello Epics has been added to more than 100,000 boards and is a
        Trello staff pick&nbsp;Power&#8209;Up."
    />
    <WhatPartnersAreSaying />
    <GettingStarted />
    <HowToGetStarted />
    <AboutUs />
    <BottomCTA />
  </PageWrapper>
)

export default CampaignPage
