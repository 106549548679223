import React from 'react'
import unicodeNonbreakingSpace from 'styles/constants/unicodeNonbreakingSpace'
import QuoteCarouselBlade from 'components/QuoteCarousel/QuoteCarouselBlade'
import useCustomerQuotes from 'hooks/useCustomerQuotes'
import usePartnerQuotes from 'hooks/usePartnerQuotes'

const WhatPartnersAreSaying: React.FunctionComponent = () => {
  const quotes = [...usePartnerQuotes(), ...useCustomerQuotes()]

  return (
    <QuoteCarouselBlade
      quotes={quotes}
      title={`What our partners are${unicodeNonbreakingSpace}saying`}
    />
  )
}

export default WhatPartnersAreSaying
