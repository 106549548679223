import React from 'react'
import styled from '@emotion/styled'
import {typography, margin, breakpoint} from 'styles'
import Wrapper from 'components/Wrapper'
import {graphql, useStaticQuery} from 'gatsby'
import invariant from 'tiny-invariant'
import {AboutUsBladeQuery} from 'landing-gatsby-graphql-generated'
import Img from 'gatsby-image'
import {trackOutboundLink} from 'analytics'
import spacing from 'styles/constants/spacing'

const Headline = styled.h3({
  ...typography.headline3,
  ...margin.bottom(24),
  textAlign: 'center',

  [breakpoint.mediumAndUp]: {
    ...margin.bottom(48),
    ...typography.headline2,
  },
})

const Paragraph = styled.p({
  ...typography.body,
  ...margin.bottom(24),
})

const ImageContainer = styled.div({
  ...margin.bottom(24),
  [breakpoint.mediumAndUp]: {
    ...margin.bottom(48),
  },
})

const AboutUs: React.FunctionComponent = () => {
  const data = useStaticQuery<AboutUsBladeQuery>(graphql`
    query AboutUsBlade {
      groupPhoto: file(relativePath: {eq: "group-photo.jpg"}) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1152) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  const groupPhoto = data.groupPhoto?.childImageSharp?.fluid
  invariant(groupPhoto, 'expected to find groupPhoto')

  return (
    <Wrapper>
      <Headline>Created by Trello power users</Headline>
      <ImageContainer>
        <Img
          css={{maxHeight: spacing(400 as any)}}
          fluid={groupPhoto as any}
          alt="Photo of Substantial’s 40+ happy employees sitting on the couches in our beautiful Seattle, WA office, all excited about Hello Epics, and ready to call all their friends and family to talk about Hello Epics as soon as the photo is taken by the photographer."
        />
      </ImageContainer>
      <Paragraph>
        Our day job is working at{' '}
        <a
          href="https://substantial.com"
          css={{color: '#EE2747'}}
          onClick={() =>
            trackOutboundLink('Substantial', 'https://substantial.com')
          }
        >
          Substantial
        </a>
        , a digital product studio. We are a team of developers, designers, and
        digital consultants, partnering with businesses to tackle complex
        projects like building a brand-new app based on the most funded game in
        Kickstarter history. We love using Trello to manage our work, but always
        wanted the ability to break down projects into smaller tasks that we
        could easily track.
      </Paragraph>
      <Paragraph>So, we decided to build a solution ourselves.</Paragraph>
      <Paragraph>
        We surveyed potential users, interviewed other Trello Power-Up
        developers, and designed dozens of prototypes. Our goal was to create
        something that was intuitive and immediately accessible to the Trello
        community by mirroring Trello’s user experience.
      </Paragraph>
      <Paragraph>The end result was Hello Epics.</Paragraph>
      <Paragraph>
        Since then, we’ve used Hello Epics on almost 200 Trello boards with our
        clients
      </Paragraph>
    </Wrapper>
  )
}

export default AboutUs
